import './Foot.css';
import Col from 'react-bootstrap/Col';

const Foot = () => {
    return (
        <footer className="footer">
            {/* <Row className='footbg'></Row> */}
            <div className="footerpic-container">
                <img src={require('../images/lablec.png')} className="footerpic" alt="" ></img>
            </div>
            <div className="adat">
                <div className="top">
                    <Col><img className="latis-logo" src={require("../images/latis-logo.png")} alt=""></img></Col>
                </div>
                <div className="top">
                    <Col>Társasházkezelés Nyíregyháza<br/>Tóth István</Col>
                    <Col>4400, Nyíregyháza, Vasvári Pál u. 1. fszt. 2.<br/>+36&nbsp;70&nbsp;23&nbsp;048&nbsp;51</Col>
                </div>
                <div className="middle">
                    <Col>
                        Honlap, arculat:<br/>
                        Latis Media - Tóth&nbsp;Márton, Fazekas&nbsp;Patrik, Sata&nbsp;Erik<br/>
                        Felelős szerkesztő: Tóth&nbsp;Márton / <a href="mailto:sajto@info-tars.hu">sajto@info-tars.hu</a>
                    </Col>
                </div>
                <div className="bottom"><Col>Minden jog fenntartva.</Col></div>
            </div>
        </footer>
    );
}

export default Foot;
