import './References.css';

const References = () => {
    return (
        <header className="test" szekcio="referenciak" style={{backgroundImage:'url('+require('../images/ref-hatter.jpg')+')'}}>
            <div className="test-welcome">
                <h2>Referenciák</h2>
                <p>A munkánk minőségét az általunk kezelt társasházak lakói igazolják hitelt érdemlően, ezért nálunk nincsenek kiemelt referenciaházak, minden társasházat egyformán kezelünk. Kérjük, keressék fel bármely általunk kezelt társasházat, kérjenek véleményt rólunk! Amennyiben az ajánlattal kapcsolatban kérdés merül fel, készséggel állunk személyesen vagy telefonon rendelkezésre.<br/>Valamennyi referenciánk: Ungvár sétány 14-20.; Móricz Zsigmond 29-39.; Kossuth 2-6.; Gádor Béla köz 2-6.; Fazekas János tér 24.; Ószőlő 103, 109; Petőfi 12-16; Keleti Márton 2.; Sarkantyú 1-9.</p>
            </div>
        </header>
    );
}

export default References;
