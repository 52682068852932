import './Contact.css';
import { Card } from './Card';
import { PhoneFill } from 'react-bootstrap-icons';
import { EnvelopeFill } from 'react-bootstrap-icons';
import { GeoAltFill } from 'react-bootstrap-icons';

const Contact = () => {
    return (
        <div className="harmas" szekcio="kapcsolat">
            <h2>Kapcsolat</h2>
            <a href="tel:+36702304851"><Card  icon={<PhoneFill/>} title="Telefonszám" text="+36&nbsp;70&nbsp;23&nbsp;048&nbsp;51" /></a>
            <a href="mailto:tarsashazkezeloiroda2005@gmail.com"><Card icon={<EnvelopeFill/>} title="E-mail cím" text={<span style={{letterSpacing:"-1px"}}>tarsashazkezeloiroda2005@gmail.com</span>} /></a>
            <a href="https://www.google.com/maps/place/Ny%C3%ADregyh%C3%A1za,+Vasv%C3%A1ri+P%C3%A1l+u.+1,+4400/@47.9603281,21.7120455,19z/" target="_blank" rel="noreferrer"><Card icon={<GeoAltFill/>} title="Levelezési cím" text={<>4400 Nyíregyháza,<br/>Vasvári Pál u. 1. fszt. 2.</>} /></a>
        </div>
    );
}

export default Contact;
