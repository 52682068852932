import './Services.css';
import { ServCard } from './Card';
import { House } from 'react-bootstrap-icons';
import { FileText } from 'react-bootstrap-icons';
import { Building } from 'react-bootstrap-icons';
import { Tools } from 'react-bootstrap-icons';
import { GraphUp } from 'react-bootstrap-icons';

const Services = () => {
    return (
        <div className="serv" szekcio="szolg">
            <h2>Szolgáltatásaink</h2>
            <div className="servs">
                <ServCard icon={<House/>} title="Társasház közös képviselet" />
                <ServCard icon={<GraphUp/>} title="Társasházi könyvelés"/>
                <ServCard icon={<Building/>} title="Irodaház kezelés" />
                <ServCard icon={<FileText/>} title="Alapító okirat, SZMSZ készítés" />
                <ServCard icon={<Tools/>} title="Hibaelhárítás" />
            </div>
        </div>
    );
}

export default Services;
