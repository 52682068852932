import './Intro.css';

const Intro = () => {
    return (
        <div className="gap-text-container" szekcio="bemutatkozas">
            <h2 className="gap-text-title">Bemutatkozunk</h2>
            <p className="gap-text">
                <img className="gap-img" src={require("../images/info.png")} alt="placeholder kép"></img>
                Tóth István vagyok, munkatársaimmal együtt, közel 20 éve foglalkozom társasházak közös képviseletével. Az eredményes munka végzéséhez szükséges szakmai végzettséggel és tapasztalattal rendelkezem. A közös képviselői munka összetett, nagy odafigyelést és türelmet igényel. Nincsenek vasárnapok, ünnepnapok, folyamatos üzemben dolgozom. Törekszem arra, hogy a lakástulajdonosok bejelentéseit, az általuk jelzett problémákat a lehető leghamarabb megoldjam. Szeretem a munkámat, szívesen foglalkozom emberekkel, ehhez megfelelő türelemmel és empátia készséggel rendelkezem. Gyakran viccelődéssel próbálok mosolyt csalogatni az emberek arcára.
            </p>
            <p className="gap-text-quote">
                Mottóm: <i>"türelem, bátorság, bölcsesség"</i>
            </p>
        </div>
    );
}

export default Intro;
