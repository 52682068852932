import './Parag.css';
import React from 'react';
import { LegisCard } from './Card';

const Parag = () => {
    return (
        <div szekcio="parag">
            <h2 className="cim">Jogszabályok</h2>
            <div className="legisls">
                <div className="parag-container">
                    <img className="parag" src={require("../images/parag.png")} alt="" />
                </div>
                <div className="legisl-container">
                    <a href="https://njt.hu/jogszabaly/2003-133-00-00" target="_blank" rel="noreferrer"><LegisCard icon={null} title={<><span className="legis-title">2003. évi CXXXIII. törvény</span> a társasházakról</>} /></a>
                    <a href="https://njt.hu/jogszabaly/2013-5-00-00" target="_blank" rel="noreferrer"><LegisCard icon={null} title={<><span className="legis-title">2013. évi V. törvény</span> a Polgári Törvénykönyvről</>} /></a>
                    <a href="https://njt.hu/jogszabaly/1997-141-00-00" target="_blank" rel="noreferrer"><LegisCard icon={null} title={<><span className="legis-title">1997. évi CXLI. törvény</span> az ingatlan-nyilvántartásról</>} /></a>
                    <a href="https://njt.hu/jogszabaly/1996-113-00-00" target="_blank" rel="noreferrer"><LegisCard icon={null} title={<><span className="legis-title">1996. évi CXIII. törvény</span> a lakástakarékpénztárakról</>} /></a>
                    <a href="https://njt.hu/jogszabaly/2011-112-00-00" target="_blank" rel="noreferrer"><LegisCard icon={null} title={<><span className="legis-title">2011. évi CXII. törvény</span> az információs önrendelkezésjogáról az információ szabadságról</>} /></a>
                    <a href="https://njt.hu/jogszabaly/2022-259-20-22" target="_blank" rel="noreferrer"><LegisCard icon={null} title={<><span className="legis-title">259/2022. (VII.21.) Korm. rendelet</span> az egyetemes szolgáltatás árszabások
maghatározásáról</>} /></a>
                </div>
            </div>
        </div>
    );
}

export default Parag;