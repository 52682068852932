// import logo from './logo.svg';
import './App.css';
import Head from './components/Head';
import Navbar from './components/Navbar';
import Intro from './components/Intro';
import Services from './components/Services';
import References from './components/References';
import Contact from './components/Contact';
import Foot from './components/Foot';
import Parag from './components/Parag';
import ReactGA from "react-ga4";

function App() {
    ReactGA.send("pageview");
    return (
        <div className="App">
            <Navbar/>
            <Head/>
            <Intro/>
            <Services/>
            <References/>
            <Parag/>
            <Contact/>
            <Foot/>
        </div>
    );
}

export default App;
