import CButton from './CButton';
import './Head.css';
import Carousel from 'react-bootstrap/Carousel';

const Head = () => {
    return (
        <>
            <Carousel
                className="header"
                fade
                controls={false}
                indicators={false}
                pause={false}
                interval={5000}
            >
                <Carousel.Item className="header-item">
                    <img
                        src={require('../images/hatter-1.jpg')}
                        alt=""
                    />
                    <Carousel.Caption className="header-welcome">
                        <p>Köszöntjük honlapunkon!</p>
                        <p>Társasházkezelés Nyíregyházán 2008 óta.</p>
                        <p>Türelem, bátorság, bölcsesség</p>
                        <CButton onClick={()=>{
                        document.querySelector("[szekcio=szolg]").scrollIntoView();
                    }}>Szolgáltatásaink</CButton>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item className="header-item">
                    <img
                        src={require('../images/hatter-2.jpg')}
                        alt=""
                    />
                    <Carousel.Caption className="header-welcome">
                        <p>Köszöntjük honlapunkon!</p>
                        <p>Társasházkezelés Nyíregyházán 2008 óta.</p>
                        <p>Türelem, bátorság, bölcsesség</p>
                        <CButton onClick={()=>{
                        document.querySelector("[szekcio=szolg]").scrollIntoView();
                    }}>Szolgáltatásaink</CButton>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </>
    );
}

export default Head;
