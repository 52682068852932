import './Card.css';
import React from 'react';

const Card = (props) => {
    return (
        <div className="info-card-container">
            <div className={"info-card card-"+(props.variant?props.variant:"orange")}>
                <div className="info-card-top">
                    <div className="info-card-icon">
                        {props.icon}
                    </div>
                </div>
                <div className="info-card-bottom">
                    <p className="info-card-title">{props.title}</p>
                    <p className="info-card-text">{props.text}</p>
                </div>
            </div>
        </div>
    );
}

const ServCard = (props) => {
    return (
        <div className="serv-card-container">
            <div className={"serv-card card-"+(props.variant?props.variant:"orange")}>
                <div className="serv-card-full">
                    <div className="serv-card-icon">
                        {props.icon}
                    </div>
                    <p className="serv-card-title">{props.title}</p>
                    {/* <p className="serv-card-text">{props.text}</p> */}
                </div>
            </div>
        </div>
    );
}

const LegisCard = (props) => {
    return (
        <div className="legis-card-container">
            <div className={"legis-card card-"+(props.variant?props.variant:"orange")}>
                <div className="legis-card-full">
                    <p className="legis-card-title">{props.title}</p>
                    {/* <p className="legis-card-text">{props.text}</p> */}
                </div>
            </div>
        </div>
    );
}

export {
    Card,
    ServCard,
    LegisCard
};
