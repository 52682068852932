import './Navbar.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Arrow90degDown } from 'react-bootstrap-icons';

const toggleMenu = () => {
    document.querySelector("body").classList.toggle("menu-open");
};

const jumpTo = (s) => {
    document.querySelector("[szekcio="+s+"]").scrollIntoView();
};

const menuItems = [
    <div key="bemutatkozas" className="navbar-item" onClick={()=>{
        jumpTo("bemutatkozas");
    }}><li>Bemutatkozás</li></div>,
    <div key="szolg" className="navbar-item" onClick={()=>{
        jumpTo("szolg");
    }}><li>Szolgáltatásaink</li></div>,
    <div key="referenciak" className="navbar-item" onClick={()=>{
        jumpTo("referenciak");
    }}><li>Referenciák</li></div>,
    <div key="parag" className="navbar-item" onClick={()=>{
        jumpTo("parag");
    }}><li>Jogszabályok</li></div>,
    <div key="kapcsolat" className="navbar-item" onClick={()=>{
        jumpTo("kapcsolat");
    }}><li>Kapcsolat</li></div>
]

const Navbar = () => {
    return (<>
        <div className="navbar-fullscreen" onClick={()=>toggleMenu()}>
            <ul className="navbar-items navbar-items-mobile">
                {menuItems.map((i)=>{
                    return {
                        ...i,
                        key:i.key+"-mobile"
                    };
                })}
            </ul>
        </div>
        <nav className={"navbar"}>
            <div className="navbar-content">
                <Row className="top-row">
                    <Col xs={8} className="name-col">
                        <p className="navbar-title">Társasházkezelés Nyíregyháza</p>
                    </Col>
                    <Col xs={4} className="phone-col">
                        <p className="navbar-phone">Vasvári&nbsp;Pál&nbsp;u.&nbsp;1.&nbsp;fszt.&nbsp;2.<br/>+36&nbsp;70&nbsp;23&nbsp;048&nbsp;51</p>
                    </Col>
                </Row>
                <ul className="navbar-items">
                    {menuItems.map((i)=>{
                        return {
                            ...i,
                            key:i.key+"-desktop"
                        };
                    })}
                </ul>
                <div className="navbar-items-rep" onClick={()=>toggleMenu()}><p className="down-arrow"><Arrow90degDown /></p><p>&nbsp;&nbsp;Menü&nbsp;megnyitása&nbsp;&nbsp;</p>{/*<p className="down-arrow"><CaretDown /></p>*/}</div>
            </div>
        </nav>
    </>);
};

export default Navbar;
